<template>
    <v-container fluid class="down-top-padding">
        <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
        <base-card heading="Registros de ações">
            <logs-component />
        </base-card>
    </v-container>
</template>

<script>
import LogsComponent from '@/components/logs/LogsComponent.vue'

export default {
  name: 'Log',
  components: {
    LogsComponent
  },
  data () {
    return {
      page: {
        title: 'Logs'
      },
      breadcrumbs: [
        {
          text: 'Início',
          disabled: false,
          to: '/'
        },
        {
          text: 'Usuário',
          disabled: false,
          to: `/user/profile/${this.$route.params.user_id}`
        },
        {
          text: 'Registros de ações',
          disabled: true,
          to: '/logs'
        }
      ]
    }
  }
}
</script>
